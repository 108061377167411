import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  gridBackground,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  standardTransition,
} from '../styles';
import { Container, Heading, HtmlContent, Overline } from './ui';
import { slugify } from '../utils';
import LiquidChemicalsItem from './LiquidChemicalsItem';

const StyledLiquidChemicals = styled.section`
  ${sectionMargins()};
`;

const StyledContainer = styled(Container)`
  max-width: 1240px;
`;

const StyledInner = styled.div`
  display: grid;
  gap: 50px;
  margin-bottom: 40px;

  ${minBreakpointQuery.small`
    gap: 60px;
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.smedium`
    gap: 70px;
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: .55fr .45fr;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 90px;
    margin-bottom: 70px;
  `}

  ${minBreakpointQuery.large`
    gap: 110px;
    margin-bottom: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 130px;
    margin-bottom: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 160px;
    margin-bottom: 120px;
  `}
`;

const StyledHeading = styled.h2`
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '22px',
    '45px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};
`;

const StyledText = styled(HtmlContent)`
  margin-top: 50px;

  p {
    margin-bottom: 0;
  }
`;

const StyledSubHeading = styled.h3`
  ${fontSize(14)};
  margin-bottom: 15px;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`   
    ${fontSize(22)};
  `}
`;

const StyledList = styled.ul``;

const StyledListItem = styled.li`
  border-bottom: 1px dotted ${brandColours.tertiary};

  &:last-child {
    border-bottom: none;
  }
`;

const StyledListLink = styled.a`
  position: relative;
  display: block;
  padding: 15px 0;
  width: 100%;
  font-weight: ${fontWeights.bold};
  ${fontSize(14)};
  transition: ${standardTransition('color')};
  border: 0;
  background: none;
  text-align: left;
  color: ${brandColours.tertiary};
  cursor: pointer;

  ${maxBreakpointQuery.medium`
    padding-right: 75px;
  `}

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.medium`
    padding-right: 45px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 20px;
    padding-right: 50px;
    padding-bottom: 20px;
    ${fontSize(22)};
  `}

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;

    ${maxBreakpointQuery.medium`
      right: 30px;
    `}
  }

  &:before {
    height: 25px;
    width: 25px;
    background-color: ${brandColours.quaternary};
    border-radius: 50%;
    transform: translateY(-50%);
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.large`
      height: 30px;
      width: 30px;
    `}
  }

  &:after {
    margin-right: 10px;
    height: 7px;
    width: 7px;
    border: 1px solid ${standardColours.white};
    border-bottom: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);

    ${minBreakpointQuery.large`
      margin-right: 12px;
      height: 8px;
      width: 8px;
    `}
  }

  &:hover {
    color: ${brandColours.nonary};

    &:before {
      background-color: ${brandColours.nonary};
    }
  }
`;

const StyledOuter = styled.div`
  ${gridBackground()};
`;

const StyledLiquidChemicalsInner = styled.div`
  ${sectionPaddings(undefined, '80px')}
`;

const StyledHeader = styled.header`
  text-align: center;
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 45px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 75px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 100px;
  `}
`;

const StyledOverline = styled(Overline)`
  &:before {
    display: none;
  }
`;

const StyledItems = styled.div`
  display: grid;
  gap: 45px;

  ${minBreakpointQuery.small`
    gap: 60px;
  `}

  ${minBreakpointQuery.medium`
    gap: 75px;
  `}

  ${minBreakpointQuery.large`
    gap: 90px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 120px;
  `}
`;

const LiquidChemicals = ({
  contentHeading,
  contentText,
  listHeading,
  itemsOverline,
  itemsHeading,
  items,
  locale,
}) => (
  <StyledLiquidChemicals>
    <StyledContainer narrow={true}>
      <StyledInner>
        <div>
          <StyledHeading>{contentHeading}</StyledHeading>
          <StyledText html={contentText} />
        </div>
        <div>
          <StyledSubHeading>{listHeading}</StyledSubHeading>
          <StyledList>
            {items.map(({ heading }, id) => (
              <StyledListItem key={id}>
                <StyledListLink href={`#${slugify(heading)}`}>
                  {heading}
                </StyledListLink>
              </StyledListItem>
            ))}
          </StyledList>
        </div>
      </StyledInner>
    </StyledContainer>
    <StyledOuter>
      <StyledContainer>
        <StyledLiquidChemicalsInner>
          <StyledHeader>
            <StyledOverline>{itemsOverline}</StyledOverline>
            <Heading>{itemsHeading}</Heading>
          </StyledHeader>
          <StyledItems>
            {items.map((item, id) => (
              <LiquidChemicalsItem key={id} locale={locale} {...item} />
            ))}
          </StyledItems>
        </StyledLiquidChemicalsInner>
      </StyledContainer>
    </StyledOuter>
  </StyledLiquidChemicals>
);

export default LiquidChemicals;
