import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  visuallyHidden,
} from '../styles';
import { QuoteButton, Svg } from './ui';
import { slugify, translateString } from '../utils';
import tickIcon from '../images/tick.svg';

const StyledLiquidChemicalsItem = styled.article`
  scroll-margin-top: 110px;

  ${minBreakpointQuery.small`
    scroll-margin-top: 130px;
  `}

  ${minBreakpointQuery.medium`
    scroll-margin-top: 150px;
  `}

  ${minBreakpointQuery.large`
    scroll-margin-top: 180px;
  `}
`;

const StyledInner = styled.div`
  display: grid;

  ${minBreakpointQuery.medium`
    grid-template-columns: .46fr .54fr;
    align-items: center;
    column-gap: 45px;
  `}

  ${minBreakpointQuery.mlarge`
    column-gap: 60px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 90px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 120px;
  `}
`;

const StyledHeading = styled.h3`
  ${fluidFontSize(
    '20px',
    '28px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledText = styled.p`
  padding-top: 20px;
  margin-top: auto;

  ${minBreakpointQuery.small`
    padding-top: 30px;
  `}

  ${minBreakpointQuery.medium`
    padding-top: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 50px;
  `}
`;

const StyledPackagingText = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;

  ${maxBreakpointQuery.medium`
    margin-top: 15px;
  `}

  ${minBreakpointQuery.small`
    flex-direction: row;
  `}

  ${minBreakpointQuery.medium`
    padding-bottom: 25px;
    margin-top: 40px;
    border-bottom: solid 1px ${brandColours.primary};
    margin-bottom: 30px;
    flex-direction: column;
  `}

  ${minBreakpointQuery.mlarge`
    flex-direction: row;
  `}
`;

const StyledApplications = styled.div`
  background-color: ${brandColours.octonary};
  border-radius: 10px;
  padding: 20px;

  ${maxBreakpointQuery.medium`
    margin-top: 15px;
  `}

  ${minBreakpointQuery.smedium`
    padding: 30px;
  `}

  ${minBreakpointQuery.medium`
    grid-area: 1/2/3/3;
  `}

  ${minBreakpointQuery.mlarge`
    padding: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    padding: 50px;
  `}
`;

const StyledApplicationsList = styled.ul`
  display: grid;
  grid-auto-columns: 1fr;
  column-gap: 30px;

  ${({ $count }) => {
    if ($count > 4) {
      return css`
        ${minBreakpointQuery.medium`
          grid-template-columns: repeat(2, 1fr);
        `}
      `;
    }
  }}

  ${({ $active }) => {
    if (!$active) {
      return css`
        ${maxBreakpointQuery.medium`
          ${visuallyHidden()};
        `}
      `;
    }
  }}
`;

const StyledSubHeading = styled.h4`
  ${fontSize(16)};
  position: relative;

  ${maxBreakpointQuery.medium`
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 20px;
      height: 3px;
      background-color: ${brandColours.tertiary};
      transform: translateY(-50%);
    }
  `}

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(22)};
  `}

  ${({ $active }) => {
    if (!$active) {
      return css`
        ${maxBreakpointQuery.medium`
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 20px;
            height: 3px;
            background-color: ${brandColours.tertiary};
            transform: translateY(-50%) rotate(90deg);
          }
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.medium`
          margin-bottom: 15px;
        `}
      `;
    }
  }}
`;

const StyledApplicationItem = styled.div`
  padding: 12px 0;
  border-bottom: 1px dotted ${brandColours.tertiary};
  display: flex;
  align-items: center;
  gap: 6px;

  ${minBreakpointQuery.small`
    padding: 14px 0;
    gap: 8px;
  `}

  ${minBreakpointQuery.medium`
    padding: 16px 0;  
  `}

  ${minBreakpointQuery.large`
    padding: 18px 0;
    gap: 10px;
  `}

  ${({ $count }) => {
    if ($count > 4) {
      return css`
        ${maxBreakpointQuery.medium`
          &:last-child {
            border-bottom: none;
          }
        `}

        ${minBreakpointQuery.medium`
          &:nth-child(${$count}) {
            border-bottom: none;
          }

          &:nth-child(${$count + 1}) {
            border-bottom: none;
          }
        `}
      `;
    } else {
      return css`
        &:last-child {
          border-bottom: none;
        }
      `;
    }
  }}
`;

const StyledTick = styled(Svg)`
  height: 16px;
  width: 16px;
`;

const StyledButton = styled(QuoteButton)`
  ${maxBreakpointQuery.medium`
    margin-top: 15px;
  `}

  ${minBreakpointQuery.medium`
    margin-right: auto;
  `}
`;

const LiquidChemicalsItem = item => {
  const { heading, text, packaging, applicationsIndustries, locale } = item;

  const items = JSON.parse(applicationsIndustries);

  const [activeItem, setActiveItem] = useState(false);

  return (
    <StyledLiquidChemicalsItem id={slugify(heading)}>
      <StyledInner>
        <div>
          <StyledHeading>{heading}</StyledHeading>
          <StyledText>{text}</StyledText>
          <StyledPackagingText>
            <span>{`${translateString('Packaging', locale)}:`}</span>
            <p>{packaging}</p>
          </StyledPackagingText>
        </div>
        <StyledApplications>
          <StyledSubHeading
            onClick={() => setActiveItem(activeItem ? false : true)}
            $active={activeItem}
          >{`${translateString(
            'Applications & Industries',
            locale
          )}:`}</StyledSubHeading>
          <StyledApplicationsList $count={items.length} $active={activeItem}>
            {items.map((item, i) => (
              <StyledApplicationItem key={i} $count={items.length}>
                <StyledTick image={tickIcon} />
                {item}
              </StyledApplicationItem>
            ))}
          </StyledApplicationsList>
        </StyledApplications>
        <StyledButton locale={locale} arrow={true} />
      </StyledInner>
    </StyledLiquidChemicalsItem>
  );
};

export default LiquidChemicalsItem;
