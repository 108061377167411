import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, Slider } from './ui';

const StyledImageSlider = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  padding-right: 20px;
  padding-left: 20px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledSlider = styled(Slider)`
  position: relative;

  .slick-list {
    overflow: visible;
  }
`;

const StyledImageWrapper = styled.div`
  padding: 0 10px;
`;

const ImageSlider = ({ heading, items }) => {
  const sliderOptions = {
    arrows: false,
    autoplay: true,
    dots: true,
    pauseOnHover: true,
  };

  return (
    <StyledImageSlider>
      <StyledContainer>
        {heading && <StyledHeading>{heading}</StyledHeading>}
        <StyledSlider numSlides={items.length} {...sliderOptions}>
          {items.map(({ fluid, alt }, i) => (
            <StyledImageWrapper>
              <Img key={`slider-image-${i}`} fluid={fluid} alt={alt} />
            </StyledImageWrapper>
          ))}
        </StyledSlider>
      </StyledContainer>
    </StyledImageSlider>
  );
};

export default ImageSlider;
