import React from 'react';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../styles';
import {
  Container,
  InViewAnimation,
  AnimatedCircles,
  Overline,
  Heading,
  HtmlContent,
  Link,
  CallbackButton,
} from './ui';

const StyledImageContent = styled.section`
  overflow: hidden;

  ${({ altColours }) => {
    if (altColours) {
      return css`
        ${sectionPaddings()};
        color: ${standardColours.white};
        background-color: ${brandColours.primary};
      `;
    } else {
      return css`
        ${sectionMargins()};
      `;
    }
  }}

  ${({ altColours, isHomepage }) => {
    if (altColours && isHomepage) {
      return css`
        padding-bottom: 0;

        ${minBreakpointQuery.tiny`
          padding-bottom: 10px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding-bottom: 20px;
        `}
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledInner = styled(InViewAnimation)`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;

    ${({ flip }) => {
      if (flip) {
        return css`
          flex-direction: row-reverse;
        `;
      }
    }}

    ${({ version }) => {
      if (version === 1) {
        return css`
          align-items: center;
        `;
      }
    }}
  `}
`;

const StyledBlock = styled.div`
  ${minBreakpointQuery.medium`
    width: 50%;
  `}

  &:last-child {
    ${minBreakpointQuery.medium`
      align-self: center;
    `}
  }
`;

const StyledImageWrapper = styled.div`
  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.medium`
          margin-right: auto;
          padding-left: 40px;
        `}

        ${minBreakpointQuery.mlarge`
          padding-left: 60px;
        `}

        ${minBreakpointQuery.large`
          padding-left: 80px;
        `}

        ${minBreakpointQuery.xlarge`
          padding-left: 100px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-left: 120px;
        `}

        ${minBreakpointQuery.xxxlarge`
          padding-left: 140px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding-left: 160px;
        `}
      `;
    } else {
      return css`
        ${minBreakpointQuery.medium`
          padding-right: 40px;
        `}

        ${minBreakpointQuery.mlarge`
          padding-right: 60px;
        `}

        ${minBreakpointQuery.large`
          padding-right: 80px;
        `}

        ${minBreakpointQuery.xlarge`
          padding-right: 100px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-right: 120px;
        `}

        ${minBreakpointQuery.xxxlarge`
          padding-right: 140px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding-right: 160px;
        `}
      `;
    }
  }}

  ${({ version }) => {
    if (version === 1) {
      return css`
        position: relative;
        padding: 0 30px;

        ${minBreakpointQuery.medium`
          padding-right: 40px;
          padding-left: 40px;
        `}
      `;
    } else if (version === 2) {
      return css`
        ${minBreakpointQuery.medium`
          height: 100%;
        `}
      `;
    }
  }}
`;

const StyledImage = styled(Img)`
  ${({ version }) => {
    if (version === 1) {
      return css`
        margin: auto;
        max-height: 540px;
        max-width: 540px;
      `;
    } else if (version === 2) {
      return css`
        ${maxBreakpointQuery.medium`
          max-height: 400px;
        `}

        ${minBreakpointQuery.medium`
          height: 100%;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  padding: 30px;

  ${minBreakpointQuery.medium`
    max-width: 600px;
  
    ${({ flip }) => {
      if (flip) {
        return css`
          margin-left: auto;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.large`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
  
  ${minBreakpointQuery.xxlarge`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding-top: 60px;
    padding-bottom: 60px;
  `}
`;

const StyledHeading = styled(Heading)``;

const StyledSubheading = styled.h3`
  margin-top: 20px;
  font-weight: ${fontWeights.regular};
  ${fontSize(20)};
  line-height: 1.5;

  ${minBreakpointQuery.tiny`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.small`
    margin-top: 24px;
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
  
  ${minBreakpointQuery.xxlarge`
    ${fontSize(28)};
  `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  p {
    margin: 20px 0 0;

    ${minBreakpointQuery.small`
      margin-top: 24px;
    `}
  }
`;

const StyledPageList = styled.ul`
  margin-top: 20px;

  ${maxBreakpointQuery.medium`
    margin-right: -30px;
    margin-left: -30px;
  `}

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}
`;

const StyledPageListItem = styled.li`
  border-bottom: 1px solid ${brandColours.octonary};

  &:first-child {
    border-top: 1px solid ${brandColours.octonary};
  }
`;

const StyledPageListLink = styled(Link)`
  position: relative;
  display: block;
  padding: 30px 0;
  width: 100%;
  font-weight: ${fontWeights.bold};
  ${fontSize(19)};
  transition: ${standardTransition('color')};

  ${maxBreakpointQuery.medium`
    padding-right: 75px;
    padding-left: 30px;
  `}

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.medium`
    padding-right: 45px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 40px;
    padding-right: 50px;
    padding-bottom: 40px;
    ${fontSize(25)};
  `}

  span {
    display: block;
    margin-top: 6px;
    font-weight: ${fontWeights.regular};
    ${fontSize(16)};

    ${minBreakpointQuery.large`
      margin-top: 8px;
      ${fontSize(18)};
    `}
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;

    ${maxBreakpointQuery.medium`
      right: 30px;
    `}
  }

  &:before {
    height: 25px;
    width: 25px;
    background-color: ${brandColours.quaternary};
    border-radius: 50%;
    transform: translateY(-50%);
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.large`
      height: 30px;
      width: 30px;
    `}
  }

  &:after {
    margin-right: 10px;
    height: 7px;
    width: 7px;
    border: 1px solid ${standardColours.white};
    border-bottom: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);

    ${minBreakpointQuery.large`
      margin-right: 12px;
      height: 8px;
      width: 8px;
    `}
  }

  &:hover {
    color: ${brandColours.nonary};

    &:before {
      background-color: ${brandColours.nonary};
    }
  }
`;

const StyledButton = styled(CallbackButton)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}
`;

const ImageContent = ({
  image: { fluid, alt },
  overline,
  heading,
  subheading,
  content,
  pageList = [],
  link,
  addCallbackButton,
  flip,
  version,
  locale,
  altColours,
  isHomepage,
}) => (
  <StyledImageContent altColours={altColours} isHomepage={isHomepage}>
    <StyledContainer wide={version === 2}>
      <StyledInner flip={flip} version={version}>
        <StyledBlock>
          <StyledImageWrapper flip={flip} version={version}>
            {version === 1 && <AnimatedCircles />}
            <StyledImage fluid={fluid} alt={alt} version={version} />
          </StyledImageWrapper>
        </StyledBlock>
        <StyledBlock>
          <StyledContent flip={flip}>
            {overline && <Overline>{overline}</Overline>}
            <StyledHeading>{heading}</StyledHeading>
            {subheading && <StyledSubheading>{subheading}</StyledSubheading>}
            {content && <StyledHtmlContent html={content} />}
            {pageList.length > 0 && (
              <StyledPageList>
                {pageList.map(page => (
                  <StyledPageListItem>
                    <StyledPageListLink to={page}>
                      {page.title}{' '}
                      {page.subtitle && <span>{page.subtitle}</span>}
                    </StyledPageListLink>
                  </StyledPageListItem>
                ))}
              </StyledPageList>
            )}
            {link && link.page && !addCallbackButton && (
              <StyledButton to={link.page} arrow={true}>
                {link.text}
              </StyledButton>
            )}
            {addCallbackButton && <StyledButton locale={locale} />}
          </StyledContent>
        </StyledBlock>
      </StyledInner>
    </StyledContainer>
  </StyledImageContent>
);

export default ImageContent;
