import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container } from './ui';

const StyledBlockquote = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()}
  text-align: center;
`;

const StyledInner = styled.figure`
  margin: 0;
  padding: 30px;
  color: ${standardColours.white};
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding: 40px;
  `};

  ${minBreakpointQuery.medium`
    padding: 50px;
  `};

  ${minBreakpointQuery.large`
    padding: 60px;
  `};
`;

const StyledQuote = styled.blockquote`
  position: relative;
  margin: 0;
  padding-top: 50px;
  color: ${standardColours.white};
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding-top: 70px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 90px;
  `}

  &:before {
    content: '“';
    position: absolute;
    top: 0;
    left: 50%;
    color: ${standardColours.white};
    font-family: sans-serif;
    ${fontSize(100)};
    line-height: 1;
    transform: translateX(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(140)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(180)};
    `}
  }
`;

const StyledText = styled.p`
  ${fluidFontSize(
    '20px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.6;
`;

const StyledNameInfo = styled.figcaption`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 26px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 32px;
    ${fontSize(20)};
  `}
`;

const StyledName = styled.p`
  margin: 8px 0;
`;

const StyledInfo = styled.p`
  margin: 8px 0;
  text-transform: uppercase;
`;

const Blockquote = ({ quote, name, information, isNewsSingle }) => (
  <StyledBlockquote isNewsSingle={isNewsSingle}>
    <Container narrow={isNewsSingle}>
      <StyledInner>
        <StyledQuote>
          <StyledText>{quote}</StyledText>
        </StyledQuote>
        {(name || information) && (
          <StyledNameInfo>
            {name && <StyledName>{name}</StyledName>}
            {information && <StyledInfo>{information}</StyledInfo>}
          </StyledNameInfo>
        )}
      </StyledInner>
    </Container>
  </StyledBlockquote>
);

export default Blockquote;
