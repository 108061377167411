import React from 'react';
import styled from 'styled-components';
import { sectionPaddings, gridBackground } from '../styles';
import { Container, Overline, Heading } from './ui';
import MediaContent from './MediaContent';

const StyledMediaContentList = styled.section`
  ${sectionPaddings()};
  ${gridBackground()};
`;

const StyledHeader = styled.header`
  text-align: center;
`;

const StyledOverline = styled(Overline)`
  &:before {
    content: none;
  }
`;

const StyledItems = styled.ol``;

const StyledItem = styled.li`
  ${sectionPaddings('40px', '100px')};

  &:last-child {
    padding-bottom: 0;
  }
`;

const MediaContentList = ({ overline, heading, items, locale }) => (
  <StyledMediaContentList>
    <Container>
      <StyledHeader>
        {overline && <StyledOverline>{overline}</StyledOverline>}
        <Heading>{heading}</Heading>
      </StyledHeader>
      <StyledItems>
        {items.map(
          ({ id, media, overline, heading, text, addQuoteButton }, i) => (
            <StyledItem key={id}>
              <MediaContent
                media={media}
                overline={overline}
                heading={heading}
                text={text}
                addQuoteButton={addQuoteButton}
                locale={locale}
                flip={i % 2}
              />
            </StyledItem>
          )
        )}
      </StyledItems>
    </Container>
  </StyledMediaContentList>
);

export default MediaContentList;
