import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
  maxBreakpointQuery,
} from '../styles';
import { Container, Overline, Heading, InViewAnimation, ArrowLink } from './ui';
import { translateString } from '../utils';

const StyledFeaturedPoints = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  margin-bottom: 40px;

  ${minBreakpointQuery.small`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 80px;
  `}
`;

const StyledOverlineHeading = styled.div``;

const StyledLink = styled(ArrowLink)`
  white-space: nowrap;

  ${maxBreakpointQuery.small`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.small`
    margin-bottom: 8px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 12px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 16px;
  `}
`;

const StyledItems = styled.ul`
  display: grid;
  row-gap: 20px;
  margin: 0 -30px;

  ${minBreakpointQuery.tiny`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    row-gap: 40px;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.xlarge`
    row-gap: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    row-gap: 60px;
  `}
`;

const StyledItem = styled.li`
  position: relative;
  padding: 30px;
  border-top: 1px solid ${brandColours.tertiary};

  ${minBreakpointQuery.medium`
    padding: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 11px;
    width: 11px;
    background-color: ${brandColours.quaternary};
    border-radius: 50%;
    transform: translateY(-50%);
  }
`;

const StyledHeading = styled.h3`
  margin-bottom: 12px;
  ${fontSize(21)};

  ${minBreakpointQuery.tiny`
    margin-bottom: 14px;
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 16px;
    ${fontSize(25)};
  `}
`;

const StyledText = styled.p`
  line-height: 1.6;
  white-space: break-spaces;
`;

const FeaturedPoints = ({ overline, heading, link, items, locale }) => (
  <StyledFeaturedPoints>
    <Container>
      <StyledHeader>
        <StyledOverlineHeading>
          {overline && <Overline>{overline}</Overline>}
          <Heading>{heading}</Heading>
        </StyledOverlineHeading>
        {link && link.page && (
          <StyledLink to={link.page}>
            {translateString('Learn more', locale)}
          </StyledLink>
        )}
      </StyledHeader>
      <StyledItems>
        {items.map(({ id, heading, text }) => (
          <StyledItem key={id}>
            <InViewAnimation>
              <StyledHeading>{heading}</StyledHeading>
              {text && <StyledText>{text}</StyledText>}
            </InViewAnimation>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledFeaturedPoints>
);

export default FeaturedPoints;
