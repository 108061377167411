import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading } from './ui';

const StyledFeaturedLogos = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tiny`
    gap: 30px;
  `};

  ${minBreakpointQuery.small`
    gap: 40px;
  `};

  ${minBreakpointQuery.medium`
    grid-template-columns: 1fr 2.5fr;
    gap: 60px;
  `};

  ${minBreakpointQuery.large`
    gap: 80px;
  `};
`;

const StyledItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  ${minBreakpointQuery.tiny`
    gap: 40px 80px;
  `};

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  `};

  ${minBreakpointQuery.small`
    gap: 50px;
  `};

  ${minBreakpointQuery.medium`
    gap: 60px;
  `};

  ${minBreakpointQuery.large`
    gap: 70px;
  `};
`;

const StyledImage = styled.img`
  margin: auto;
  max-height: 60px;

  ${minBreakpointQuery.tiny`
    max-height: 80px;
  `};

  ${minBreakpointQuery.small`
    max-height: 100px;
  `};

  ${minBreakpointQuery.large`
    max-height: 120px;
  `};
`;

const FeaturedLogos = ({ heading, items }) => (
  <StyledFeaturedLogos>
    <Container>
      <StyledInner>
        <Heading>{heading}</Heading>
        <StyledItems>
          {items.map(({ url, alt }, i) => (
            <StyledImage
              key={`featured-logo-${i}`}
              src={url}
              alt={alt}
              loading="lazy"
            />
          ))}
        </StyledItems>
      </StyledInner>
    </Container>
  </StyledFeaturedLogos>
);

export default FeaturedLogos;
