import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  headingStyles,
  standardTransition,
} from '../styles';
import { Link, InViewAnimation } from './ui';
import { translateString } from '../utils';

// moved up for hover
const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.medium};
  text-align: center;
  text-transform: uppercase;
  background-color: ${brandColours.tertiary};
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateX(-100%);
  transition: ${standardTransition('transform')};

  ${minBreakpointQuery.medium`
    padding: 25px;
  `}

  ${minBreakpointQuery.large`
    padding: 30px;
  `}

  :before {
    content: '➞';
    position: absolute;
    top: 40px;
    right: 50%;
    font-weight: ${fontWeights.bold};
    ${fontSize(22)};
    transform: rotate(90deg) translate(100%, -50%);
    transform-origin: top right;

    ${minBreakpointQuery.large`
      ${fontSize(26)};
    `}
  }
`;

const StyledPageImageCard = styled.article`
  position: relative;
  background-color: ${brandColours.senary};
  cursor: pointer;
  overflow: hidden;

  &:nth-child(3n - 1) {
    background-color: ${brandColours.quinary};
  }

  &:nth-child(3n) {
    background-color: ${brandColours.septenary};
  }

  &:hover {
    ${StyledLink} {
      transform: rotate(180deg) translateX(0);
    }
  }
`;

const StyledInner = styled(InViewAnimation)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledContent = styled.div`
  padding: 30px 30px 0;

  ${minBreakpointQuery.medium`
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 60px;
    padding-right: 60px;
    padding-left: 60px;
  `}
`;

const StyledHeading = styled.h3`
  ${headingStyles('28px', '35px')};
`;

const StyledText = styled.p`
  margin-top: 8px;

  ${minBreakpointQuery.large`
    margin-top: 12px;
    ${fontSize(18)};
  `}
`;

const StyledImageWrapper = styled.div`
  margin: 20px 20px -10px;

  ${minBreakpointQuery.medium`
    margin: 30px 30px -20px;
  `}

  ${minBreakpointQuery.large`
    margin: 40px 40px -30px;
  `}
`;

const StyledImage = styled(Img)`
  margin-left: auto;
  max-width: 180px;

  ${minBreakpointQuery.tiny`
    max-width: 240px;
  `}

  ${minBreakpointQuery.medium`
    max-width: 300px;
  `}

  ${minBreakpointQuery.large`
    max-width: 360px;
  `}
`;

const PageImageCard = ({ heading, slug, text, image, locale }) => (
  <StyledPageImageCard
    onClick={() => {
      navigate(slug);
    }}
  >
    <StyledInner>
      <StyledContent>
        <StyledHeading>{heading}</StyledHeading>
        {text && <StyledText>{text}</StyledText>}
      </StyledContent>
      {image && (
        <StyledImageWrapper>
          <StyledImage fluid={image.fluid} alt={image.alt} />
        </StyledImageWrapper>
      )}
      <StyledLink to={slug}>
        {translateString('View details', locale)}
      </StyledLink>
    </StyledInner>
  </StyledPageImageCard>
);

export default PageImageCard;

export const PageImageCardFragment = graphql`
  fragment PageImageCardFragment on Node {
    ... on DatoCmsPage {
      id
      title
      locale
      slug
      subtitle
      image: bannerImage {
        ...PageImageCardImageFragment
      }
    }
    ... on DatoCmsSystem {
      id
      title
      locale
      slug
      subtitle
      image: featuredImage {
        ...PageImageCardImageFragment
      }
      internal {
        type
      }
    }
  }

  fragment PageImageCardImageFragment on DatoCmsFileField {
    fluid(
      maxHeight: 320
      imgixParams: { auto: "compress", fit: "crop", h: "320" }
    ) {
      ...GatsbyDatoCmsFluid
    }
    alt
  }
`;
