import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { headingStyles, visuallyHidden } from '../styles';
import { Container } from './ui';
import PageImageCard from './PageImageCard';
import { buildUrl } from '../utils';

const StyledPageImageCards = styled.section`
  ${sectionMargins('30px', '70px')};
`;

const StyledHeading = styled.h2`
  ${({ visuallyHide }) => {
    if (visuallyHide) {
      return css`
        ${visuallyHidden()};
      `;
    } else {
      return css`
        ${headingStyles(undefined, '45px')};
        margin-bottom: 20px;

        ${minBreakpointQuery.small`
          margin-bottom: 30px;
        `}

        ${minBreakpointQuery.large`
          margin-bottom: 40px;
        `}
      `;
    }
  }}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tiny`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    row-gap: 40px;
  `}

  ${minBreakpointQuery.large`
    row-gap: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    row-gap: 60px;
  `}
`;

const PageImageCards = ({ heading, visuallyHideHeading, items, locale }) =>
  items.length > 0 && (
    <StyledPageImageCards>
      <Container>
        <StyledHeading visuallyHide={visuallyHideHeading}>
          {heading}
        </StyledHeading>
        <StyledInner>
          {items.map(item => (
            <PageImageCard
              key={item.id}
              heading={item.title}
              slug={buildUrl(item.slug, item)}
              text={item.subtitle}
              image={item.image}
              locale={locale}
            />
          ))}
        </StyledInner>
      </Container>
    </StyledPageImageCards>
  );

export default PageImageCards;
