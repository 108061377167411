import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container } from './ui';

const StyledContainedImage = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()}
`;

const ContainedImage = ({ image: { fluid, alt }, isNewsSingle }) => (
  <StyledContainedImage isNewsSingle={isNewsSingle}>
    <Container narrow={isNewsSingle}>
      <Img fluid={fluid} alt={alt} />
    </Container>
  </StyledContainedImage>
);

export default ContainedImage;
