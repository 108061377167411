import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  brandColours,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Overline, Heading, Slider, Link, Button } from './ui';
import {
  getCollectionLocaleFields,
  getSingleLocaleFields,
  buildUrl,
  translateString,
} from '../utils';

const StyledFeaturedSystems = styled.section`
  overflow: hidden;
  text-align: center;

  ${({ altColours }) => {
    if (altColours) {
      return css`
        ${sectionPaddings()};
        color: ${standardColours.white};
        background-color: ${brandColours.primary};
      `;
    } else {
      return css`
        ${sectionMargins()};
      `;
    }
  }}
`;

const StyledOuter = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    right: -10%;
    bottom: 20px;
    left: -10%;
    display: block;
    height: 50vw;
    border-bottom: 1px dashed;
    border-radius: 50%;
    opacity: 0.4;

    ${({ altColours }) => {
      if (altColours) {
        return css`
          border-color: ${standardColours.white};
        `;
      } else {
        return css`
          border-color: ${brandColours.primary};
        `;
      }
    }}

    ${minBreakpointQuery.medium`
      bottom: 24px;
    `}
  }
`;

const StyledInner = styled.div`
  position: relative;
`;

const StyledHeader = styled.header`
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledOverline = styled(Overline)`
  padding-left: 0;

  &:before {
    content: none;
  }
`;

const StyledHeading = styled(Heading)``;

// moved up for transition
const StyledContent = styled.div`
  margin-top: 20px;
  opacity: 0;
  transition: ${standardTransition('opacity')};

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const StyledSlider = styled(Slider)`
  margin: auto;
  max-width: 500px;

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    opacity: 0.5;
    transform: scale(0.8);
    transition: ${standardTransition('opacity')},
      ${standardTransition('transform')};

    &.slick-current {
      opacity: 1;
      transform: scale(1);

      ${StyledContent} {
        opacity: 1;
      }
    }
  }

  .slick-arrow {
    ${maxBreakpointQuery.small`
      ${visuallyHidden()};
    `}
  }

  ${({ altColours }) => {
    if (altColours) {
      return css`
        .slick-arrow {
          border-color: ${standardColours.white};

          &:after {
            color: ${standardColours.white};
          }
        }
      `;
    }
  }}
`;

const StyledItem = styled.article`
  padding: 10px;
  text-align: center;
  cursor: pointer;
`;

const StyledImage = styled(Img)`
  margin: auto;
  max-width: 300px;
`;

const StyledTitle = styled.h3`
  ${fontSize(28)};

  ${minBreakpointQuery.small`
    ${fontSize(32)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(36)};
  `}
`;

const StyledText = styled.p`
  margin-top: 12px;

  ${minBreakpointQuery.small`
    margin-top: 14px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
    ${fontSize(20)};
  `}
`;

const StyledFooter = styled.footer`
  margin-top: 30px;

  ${minBreakpointQuery.small`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 60px;
  `}
`;

const FeaturedSystems = ({ overline, heading, locale, altColours }) => {
  const { allDatoCmsSystem, allDatoCmsSystemsArchive } = useStaticQuery(graphql`
    query FeaturedSystemsQuery {
      allDatoCmsSystem(filter: { slug: { ne: null } }) {
        group(field: locale) {
          fieldValue
          nodes {
            id
            title
            slug
            subtitle
            featuredImage {
              fluid(
                maxWidth: 280
                imgixParams: { auto: "compress", fit: "crop", w: "280" }
              ) {
                ...GatsbyDatoCmsFluid
              }
              alt
            }
            ...LinkFragment
          }
        }
      }
      allDatoCmsSystemsArchive {
        nodes {
          ...LinkFragment
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsSystem, locale);
  const systemsArchive = getSingleLocaleFields(
    allDatoCmsSystemsArchive,
    locale
  );

  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <StyledFeaturedSystems altColours={altColours}>
      <StyledOuter altColours={altColours}>
        <Container narrow={true}>
          <StyledInner>
            <StyledHeader>
              {overline && <StyledOverline>{overline}</StyledOverline>}
              <StyledHeading>{heading}</StyledHeading>
            </StyledHeader>
            <StyledSlider altColours={altColours} sliderOptions={sliderOptions}>
              {nodes.map(node => {
                const {
                  id,
                  title,
                  slug,
                  subtitle,
                  featuredImage: { fluid, alt },
                } = node;

                return (
                  <StyledItem
                    key={id}
                    onClick={() => {
                      navigate(buildUrl(slug, node));
                    }}
                  >
                    <StyledImage fluid={fluid} alt={alt} />
                    <StyledContent>
                      <StyledTitle>
                        <Link to={node}>{title}</Link>
                      </StyledTitle>
                      {subtitle && <StyledText>{subtitle}</StyledText>}
                    </StyledContent>
                  </StyledItem>
                );
              })}
            </StyledSlider>
            <StyledFooter>
              <Button to={systemsArchive} altColours={altColours}>
                {translateString('View all Systems', locale)}
              </Button>
            </StyledFooter>
          </StyledInner>
        </Container>
      </StyledOuter>
    </StyledFeaturedSystems>
  );
};

export default FeaturedSystems;
