import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import Accordion from './Accordion';
import Blockquote from './Blockquote';
import CallbackCta from './CallbackCta';
import ContainedImage from './ContainedImage';
import ContentFeaturedPoints from './ContentFeaturedPoints';
import ContentList from './ContentList';
import Content from './Content';
import ExternalVideo from './ExternalVideo';
import FeaturedCaseStudy from './FeaturedCaseStudy';
import FeaturedLogos from './FeaturedLogos';
import FeaturedPoints from './FeaturedPoints';
import FeaturedSystems from './FeaturedSystems';
import FullWidthImage from './FullWidthImage';
import ImageContent from './ImageContent';
import ImageFeaturedPoints from './ImageFeaturedPoints';
import ImageSlider from './ImageSlider';
import InternalVideo from './InternalVideo';
import LatestNews from './LatestNews';
import MediaContentList from './MediaContentList';
import MixerCategories from './MixerCategories';
import PageImageCards from './PageImageCards';
import PageTextCards from './PageTextCards';
import QuoteCta from './QuoteCta';
import Tabs from './Tabs';
import Testimonials from './Testimonials';
import LiquidChemicals from './LiquidChemicals';

const StyledModularBlocks = styled.div`
  ${sectionMargins()};
`;

const ModularBlocks = ({ items, subpages, locale, isNewsSingle }) => {
  const ModularBlocksComponent = isNewsSingle
    ? StyledModularBlocks
    : React.Fragment;

  return (
    <ModularBlocksComponent>
      {items.map(item => (
        <React.Fragment key={item.id}>
          {item.model.apiKey === 'accordion_modular_block' && (
            <Accordion
              heading={item.instance.heading}
              items={item.instance.accordionItems}
            />
          )}
          {item.model.apiKey === 'blockquote_modular_block' && (
            <Blockquote
              quote={item.quote}
              name={item.name}
              information={item.information}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'callback_cta_modular_block' && (
            <CallbackCta heading={item.heading} locale={locale} />
          )}
          {item.model.apiKey === 'contained_image_modular_block' && (
            <ContainedImage image={item.image} isNewsSingle={isNewsSingle} />
          )}
          {item.model.apiKey === 'content_featured_points_modular_block' && (
            <ContentFeaturedPoints
              contentHeading={item.contentHeading}
              content={item.content}
              itemsHeading={item.featuredPointsHeading}
              items={item.featuredPoints}
            />
          )}
          {item.model.apiKey === 'content_list_modular_block' && (
            <ContentList
              heading={item.instance.heading}
              items={item.instance.contentList}
            />
          )}
          {item.model.apiKey === 'content_modular_block' && (
            <Content
              html={item.content}
              contain={item.contain}
              twoColumns={item.twoColumns}
              highlight={item.highlight}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'external_video_modular_block' && (
            <ExternalVideo
              video={item.externalVideo}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'featured_case_study_modular_block' && (
            <FeaturedCaseStudy locale={locale} />
          )}
          {item.model.apiKey === 'featured_logos_modular_block' && (
            <FeaturedLogos heading={item.heading} items={item.items} />
          )}
          {item.model.apiKey === 'featured_points_modular_block' && (
            <FeaturedPoints
              overline={item.instance.overline}
              heading={item.instance.heading}
              link={{
                page: item.instance.linkPage,
              }}
              items={item.instance.featuredPoints}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'featured_systems_modular_block' && (
            <FeaturedSystems
              overline={item.overline}
              heading={item.heading}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'full_width_image_modular_block' && (
            <FullWidthImage image={item.image} />
          )}
          {item.model.apiKey === 'image_content_modular_block_v1' && (
            <ImageContent
              image={item.image}
              overline={item.overline}
              heading={item.heading}
              subheading={item.subheading}
              content={item.content}
              pageList={item.pageList}
              link={{
                page: item.linkPage,
                text: item.linkText,
              }}
              addCallbackButton={item.addCallbackButton}
              locale={locale}
              flip={item.flip}
              version={1}
            />
          )}
          {item.model.apiKey === 'image_content_modular_block_v2' && (
            <ImageContent
              image={item.image}
              overline={item.overline}
              heading={item.heading}
              subheading={item.subheading}
              content={item.content}
              pageList={item.pageList}
              link={{
                page: item.linkPage,
                text: item.linkText,
              }}
              addCallbackButton={item.addCallbackButton}
              locale={locale}
              flip={item.flip}
              version={2}
            />
          )}
          {item.model.apiKey === 'image_featured_points_modular_block' && (
            <ImageFeaturedPoints
              heading={item.heading}
              items={item.featuredPoints}
              image={item.image}
              text={item.text}
              addQuoteButton={item.addQuoteButton}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'image_slider_modular_block' && (
            <ImageSlider heading={item.heading} items={item.images} />
          )}
          {item.model.apiKey === 'internal_video_modular_block' && (
            <InternalVideo
              video={item.internalVideo}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'latest_news_modular_block' && (
            <LatestNews locale={locale} />
          )}
          {item.model.apiKey === 'liquid_chemicals_modular_block' && (
            <LiquidChemicals
              contentHeading={item.contentHeading}
              contentText={item.contentText}
              listHeading={item.listHeading}
              itemsOverline={item.itemsOverline}
              itemsHeading={item.itemsHeading}
              items={item.items}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'media_content_list_modular_block' && (
            <MediaContentList
              overline={item.instance.overline}
              heading={item.instance.heading}
              items={item.instance.mediaContentList}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'mixer_categories_modular_block' && (
            <MixerCategories
              heading={item.heading}
              items={item.items}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'page_image_cards_modular_block' && (
            <PageImageCards
              heading={item.heading}
              visuallyHideHeading={item.visuallyHideHeading}
              items={item.pages}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'page_text_cards_modular_block' && (
            <PageTextCards items={item.pages} />
          )}
          {item.model.apiKey === 'quote_cta_modular_block' && (
            <QuoteCta
              overline={item.overline}
              heading={item.heading}
              text={item.text}
              image={item.image}
              locale={locale}
            />
          )}
          {item.model.apiKey === 'subpage_text_cards_modular_block' && (
            <PageTextCards items={subpages} />
          )}
          {item.model.apiKey === 'tabs_modular_block' && (
            <Tabs heading={item.instance.heading} items={item.instance.tabs} />
          )}
          {item.model.apiKey === 'testimonials_modular_block' && (
            <Testimonials
              heading={item.instance.heading}
              items={item.instance.testimonials}
              isNewsSingle={isNewsSingle}
            />
          )}
        </React.Fragment>
      ))}
    </ModularBlocksComponent>
  );
};

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      accordionItems {
        id
        heading
        content
      }
    }
  }

  fragment BlockquoteModularBlockFragment on DatoCmsBlockquoteModularBlock {
    id
    model {
      apiKey
    }
    quote
    name
    information
  }

  fragment CallbackCtaModularBlockFragment on DatoCmsCallbackCtaModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      fluid(
        maxWidth: 1140
        imgixParams: { auto: "compress", fit: "crop", w: "1140" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }

  fragment ContentFeaturedPointsModularBlockFragment on DatoCmsContentFeaturedPointsModularBlock {
    id
    model {
      apiKey
    }
    contentHeading
    content
    featuredPointsHeading
    featuredPoints
  }

  fragment ContentListModularBlockFragment on DatoCmsContentListModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      contentList {
        heading
        text
      }
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    twoColumns
    highlight
    contain
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    externalVideo {
      height
      width
      provider
      providerUid
      url
    }
  }

  fragment FeaturedCaseStudyModularBlockFragment on DatoCmsFeaturedCaseStudyModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      url
      alt
    }
  }

  fragment FeaturedPointsModularBlockFragment on DatoCmsFeaturedPointsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      overline
      heading
      linkPage {
        ...LinkFragment
      }
      featuredPoints {
        id
        heading
        text
      }
    }
  }

  fragment FeaturedSystemsModularBlockFragment on DatoCmsFeaturedSystemsModularBlock {
    id
    model {
      apiKey
    }
    overline
    heading
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      fluid(
        maxWidth: 2000
        imgixParams: { auto: "compress", fit: "crop", w: "2000" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }

  fragment ImageContentModularBlockV1Fragment on DatoCmsImageContentModularBlockV1 {
    id
    model {
      apiKey
    }
    image {
      fluid(maxHeight: 540, maxWidth: 540) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
    overline
    heading
    subheading
    content
    pageList {
      ...LinkFragment
    }
    linkPage {
      ...LinkFragment
    }
    linkText
    addCallbackButton
    flip
  }

  fragment ImageContentModularBlockV2Fragment on DatoCmsImageContentModularBlockV2 {
    id
    model {
      apiKey
    }
    image {
      fluid(
        maxHeight: 740
        maxWidth: 870
        imgixParams: { auto: "compress", fit: "crop", h: "740", w: "870" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
    overline
    heading
    subheading
    content
    pageList {
      ...LinkFragment
    }
    linkPage {
      ...LinkFragment
    }
    linkText
    addCallbackButton
    flip
  }

  fragment ImageFeaturedPointsModularBlockFragment on DatoCmsImageFeaturedPointsModularBlock {
    id
    model {
      apiKey
    }
    heading
    featuredPoints
    image {
      fluid(
        maxWidth: 440
        imgixParams: { auto: "compress", fit: "crop", w: "440" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
    text
    addQuoteButton
  }

  fragment ImageSliderModularBlockFragment on DatoCmsImageSliderModularBlock {
    id
    model {
      apiKey
    }
    heading
    images {
      fluid(
        maxHeight: 600
        maxWidth: 1140
        imgixParams: { auto: "compress", fit: "crop", h: "600", w: "1140" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }

  fragment InternalVideoModularBlockFragment on DatoCmsInternalVideoModularBlock {
    id
    model {
      apiKey
    }
    internalVideo {
      format
      url
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }

  fragment LatestNewsModularBlockFragment on DatoCmsLatestNewsModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment LiquidChemicalsModularBlockFragment on DatoCmsLiquidChemicalsModularBlock {
    id
    model {
      apiKey
    }
    contentHeading
    contentText
    listHeading
    itemsOverline
    itemsHeading
    items {
      heading
      text
      packaging
      applicationsIndustries
    }
  }

  fragment MediaContentListModularBlockFragment on DatoCmsMediaContentListModularBlock {
    id
    model {
      apiKey
    }
    instance {
      overline
      heading
      mediaContentList {
        id
        media {
          isImage
          format
          fluid(
            maxWidth: 800
            imgixParams: { auto: "compress", fit: "crop", w: "800" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
          url
          video {
            thumbnailUrl
            mp4Url
          }
        }
        overline
        heading
        text
        addQuoteButton
      }
    }
  }

  fragment MixerCategoriesModularBlockFragment on DatoCmsMixerCategoriesModularBlock {
    id
    model {
      apiKey
    }
    heading
    items {
      heading
      text
      image {
        fluid(
          maxWidth: 280
          imgixParams: { auto: "compress", fit: "crop", w: "280" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      headingContentBlocks {
        heading
        content
      }
    }
  }

  fragment PageImageCardsModularBlockFragment on DatoCmsPageImageCardsModularBlock {
    id
    model {
      apiKey
    }
    heading
    visuallyHideHeading
    pages {
      ...PageImageCardFragment
    }
  }

  fragment PageTextCardsModularBlockFragment on DatoCmsPageTextCardsModularBlock {
    id
    model {
      apiKey
    }
    pages {
      ...PageTextCardFragment
    }
  }

  fragment QuoteCtaModularBlockFragment on DatoCmsQuoteCtaModularBlock {
    id
    model {
      apiKey
    }
    overline
    heading
    text
    image {
      fluid(
        maxWidth: 620
        imgixParams: { auto: "compress", fit: "crop", w: "620" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }

  fragment SubpageTextCardsModularBlockFragment on DatoCmsSubpageTextCardsModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      tabs {
        id
        heading
        content
      }
    }
  }

  fragment TestimonialsModularBlockFragment on DatoCmsTestimonialsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      testimonials {
        id
        quote
        name
        information
      }
    }
  }
`;
