import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  gridBackground,
  visuallyHidden,
} from '../styles';
import { Container, ArrowLink } from './ui';
import { translateString } from '../utils';
import CaseStudyCard from './CaseStudyCard';

const StyledFeaturedCaseStudy = styled.section`
  ${sectionPaddings()};
  ${gridBackground()};
`;

const StyledContainer = styled(Container)`
  ${maxBreakpointQuery.tiny`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const StyledHeader = styled.header`
  ${visuallyHidden()};
`;

const StyledFooter = styled.footer`
  ${minBreakpointQuery.small`
    text-align: right;
  `}
`;

const StyledLink = styled(ArrowLink)`
  margin-top: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const FeaturedCaseStudy = ({ locale }) => {
  const { datoCmsCaseStudy } = useStaticQuery(graphql`
    query FeaturedCaseStudyQuery {
      datoCmsCaseStudy(position: { eq: 1 }) {
        ...CaseStudyCardFragment
      }
    }
  `);

  return (
    <StyledFeaturedCaseStudy>
      <StyledContainer>
        <StyledHeader>
          <h2>{translateString('Featured Case Study', locale)}</h2>
        </StyledHeader>
        <CaseStudyCard
          overline={translateString('Recent Case Study', locale)}
          heading={datoCmsCaseStudy.title}
          link={datoCmsCaseStudy}
          image={datoCmsCaseStudy.featuredImage}
          locale={locale}
        />
        <StyledFooter>
          <StyledLink to={{ slug: 'case-studies', locale: locale }}>
            {translateString('View all case studies', locale)}
          </StyledLink>
        </StyledFooter>
      </StyledContainer>
    </StyledFeaturedCaseStudy>
  );
};

export default FeaturedCaseStudy;
