import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { sectionMargins } from '../styles';

const StyledFullWidthImage = styled.section`
  ${sectionMargins()};
`;

const FullWidthImage = ({ image: { fluid, alt } }) => (
  <StyledFullWidthImage>
    <Img fluid={fluid} alt={alt} />
  </StyledFullWidthImage>
);

export default FullWidthImage;
