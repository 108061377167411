import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  headingStyles,
} from '../styles';
import { Container, Grid, InViewAnimation, HtmlContent } from './ui';

const StyledContentFeaturedPoints = styled.section`
  ${sectionMargins()};
`;

const StyledGroup = styled(InViewAnimation)``;

const StyledHeading = styled.h2`
  ${headingStyles(undefined, '45px')}
`;

const StyledContent = styled(HtmlContent)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}
`;

const StyledSubheading = styled.h3`
  ${headingStyles('26px', '40px')}
`;

const StyledItems = styled.ul``;

const StyledItem = styled.li`
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1.5;
  border-top: 1px dashed ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding-top: 24px;
    padding-bottom: 24px;
  `}

  &:first-child {
    border-top: none;
  }
`;

const ContentFeaturedPoints = ({
  contentHeading,
  content,
  itemsHeading,
  items,
}) => (
  <StyledContentFeaturedPoints>
    <Container>
      <Grid>
        <StyledGroup>
          <StyledHeading>{contentHeading}</StyledHeading>
          <StyledContent html={content} />
        </StyledGroup>
        <StyledGroup>
          <StyledSubheading>{itemsHeading}</StyledSubheading>
          <StyledItems>
            {JSON.parse(items).map(item => (
              <StyledItem>{item}</StyledItem>
            ))}
          </StyledItems>
        </StyledGroup>
      </Grid>
    </Container>
  </StyledContentFeaturedPoints>
);

export default ContentFeaturedPoints;
