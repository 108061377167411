import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, InViewAnimation, QuoteButton } from './ui';

const StyledImageFeaturedPoints = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  margin: auto;
  max-width: 680px;
  text-align: center;
`;

const StyledItemsImage = styled.div`
  position: relative;
`;

const StyledItems = styled.ul`
  ${minBreakpointQuery.small`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.medium`
    padding-top: 40px;
    padding-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 60px;
    padding-bottom: 60px;
  `}
`;

const StyledItem = styled.li`
  position: relative;
  margin: 30px 0;
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};

  ${maxBreakpointQuery.small`
    padding-left: 100px;
  `}

  ${minBreakpointQuery.small`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 46%;
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(22)};
  `}

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    border-bottom: 1px dashed ${brandColours.primary};

    ${maxBreakpointQuery.small`
      left: -30px;
      width: 80px;
    `}

    ${minBreakpointQuery.small`
      width: 100px;
    `}

    ${minBreakpointQuery.medium`
      width: 140px;
    `}

    ${minBreakpointQuery.large`
      width: 180px;
    `}
  }

  &:after {
    height: 17px;
    width: 17px;
    background-color: ${brandColours.quaternary};
    border: 6px solid ${standardColours.white};
    border-radius: 50%;
    box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.4);

    ${maxBreakpointQuery.small`
      left: 50px;
    `}
  }

  &:nth-child(odd) {
    ${minBreakpointQuery.small`
      align-self: flex-start;
      padding-right: 120px;
    `}

    ${minBreakpointQuery.medium`
      padding-right: 160px;
    `}

    ${minBreakpointQuery.large`
      padding-right: 200px;
    `}

    ${minBreakpointQuery.xxlarge`
      padding-right: 220px;
    `}

    &:before,
    &:after {
      ${minBreakpointQuery.small`
        right: 0;
      `}
    }
  }

  &:nth-child(even) {
    ${minBreakpointQuery.small`
      align-self: flex-end;
      padding-left: 120px;
    `}

    ${minBreakpointQuery.medium`
      padding-left: 160px;
    `}

    ${minBreakpointQuery.large`
      padding-left: 200px;
    `}

    ${minBreakpointQuery.xxlarge`
      padding-left: 220px;
    `}

    &:before,
    &:after {
      ${minBreakpointQuery.small`
        left: 0;
      `}
    }
  }
`;

const StyledImage = styled(Img)`
  margin: auto;
  width: 100%;
  max-width: 260px;

  ${maxBreakpointQuery.small`
    position: relative;
    margin-top: 40px;
  `}

  ${minBreakpointQuery.small`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    max-width: 280px;
    transform: translateX(-50%);
  `}

  ${minBreakpointQuery.medium`
    max-width: 300px;
  `}

  ${minBreakpointQuery.large`
    max-width: 320px;
  `}

  ${minBreakpointQuery.xxlarge`
    max-width: 340px;
  `}
`;

const StyledFooter = styled.footer`
  position: relative;
  text-align: center;
`;

const StyledText = styled.p`
  margin-top: 22px;
  padding: 20px;
  line-height: 1.6;
  border: 1px solid ${brandColours.octonary};
  border-radius: 30px;

  ${minBreakpointQuery.large`
    margin-top: 30px;
    ${fontSize(18)};
  `}
`;

const StyledButton = styled(QuoteButton)`
  margin-top: 22px;

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const ImageFeaturedPoints = ({
  heading,
  items,
  image: { fluid, alt },
  text,
  addQuoteButton,
  locale,
}) => (
  <StyledImageFeaturedPoints>
    <Container narrow={true}>
      <StyledHeader>
        <Heading>{heading}</Heading>
      </StyledHeader>
      <StyledItemsImage>
        <StyledItems>
          {JSON.parse(items).map(item => (
            <StyledItem>
              <InViewAnimation>{item}</InViewAnimation>
            </StyledItem>
          ))}
        </StyledItems>
        <StyledImage
          fluid={fluid}
          alt={alt}
          style={{ position: '' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </StyledItemsImage>
      {(text || addQuoteButton) && (
        <StyledFooter>
          {text && <StyledText>{text}</StyledText>}
          {addQuoteButton && <StyledButton arrow={true} locale={locale} />}
        </StyledFooter>
      )}
    </Container>
  </StyledImageFeaturedPoints>
);

export default ImageFeaturedPoints;
