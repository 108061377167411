import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';
import PageTextCard from './PageTextCard';
import { buildUrl } from '../utils';

const StyledPageTextCards = styled.section`
  ${sectionMargins()};
`;

const StyledItems = styled.div`
  display: grid;
  grid-gap: 20px;
  margin: 0 -30px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
  `}

  ${minBreakpointQuery.medium`
    grid-gap: 30px;
  `}
`;

const PageTextCards = ({ items }) =>
  items.length > 0 && (
    <StyledPageTextCards>
      <Container>
        <StyledItems>
          {items.map(item => (
            <PageTextCard
              key={item.id}
              title={item.title}
              slug={buildUrl(item.slug, item)}
              subtitle={item.subtitle}
            />
          ))}
        </StyledItems>
      </Container>
    </StyledPageTextCards>
  );

export default PageTextCards;
