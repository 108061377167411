import React from 'react';
import { navigate, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  standardColours,
  fontSize,
  fontWeights,
  headingStyles,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledPageTextCard = styled.article`
  width: 100%;
  cursor: pointer;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 55px;
  padding-right: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: ${standardTransition('color')},
    ${standardTransition('background-color')};

  ${minBreakpointQuery.mlarge`
    padding-top: 50px;
    padding-bottom: 50px;
  `}
  &:hover {
    color: ${standardColours.white};

    &:after {
      color: ${standardColours.white};
    }
  }

  &:nth-child(odd) {
    background-color: ${brandColours.senary};

    &:hover {
      background-color: ${brandColours.nonary};
    }
  }

  &:nth-child(even) {
    background-color: ${brandColours.quinary};

    &:hover {
      background-color: ${brandColours.nonary};
    }
  }

  &:after {
    content: '➞';
    color: ${brandColours.primary};
    ${fontSize(34)};
    font-weight: bold;
    transition: ${standardTransition('color')};
    display: block;
    text-align: right;
    margin-top: 55px;

    ${minBreakpointQuery.mlarge`
        margin-top: 130px;
    `}
  }
`;

const StyledTitle = styled.h3`
  display: block;
  ${headingStyles(undefined, '45px')}
`;

const StyledText = styled.p`
  margin-top: 25px;
  font-weight: ${fontWeights.regular};
  ${fontSize(18)};
`;

const PageTextCard = ({ title, slug, subtitle }) => {
  return (
    <StyledPageTextCard
      onClick={() => {
        navigate(buildUrl(slug));
      }}
    >
      <StyledTitle>
        <Link to={slug}>{title}</Link>
      </StyledTitle>
      {subtitle && <StyledText>{subtitle}</StyledText>}
    </StyledPageTextCard>
  );
};

export default PageTextCard;

export const PageTextCardFragment = graphql`
  fragment PageTextCardFragment on DatoCmsPage {
    id
    slug
    treeParent {
      slug
      treeParent {
        slug
        treeParent {
          slug
        }
      }
    }
    title
    subtitle
    position
  }
`;
