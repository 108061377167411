import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container, Video } from './ui';

const StyledExternalVideo = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()}
`;

const ExternalVideo = ({ video, isNewsSingle }) => (
  <StyledExternalVideo isNewsSingle={isNewsSingle}>
    <Container narrow={isNewsSingle}>
      <Video data={video} />
    </Container>
  </StyledExternalVideo>
);

export default ExternalVideo;
