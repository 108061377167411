import React from 'react';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Heading, Slider } from './ui';

const StyledTestimonials = styled.section`
  ${sectionMargins()};
  text-align: center;
`;

const commonItemsStyles = () => {
  return css`
    margin-top: 10px;
    position: relative;
    padding-top: 50px;

    ${minBreakpointQuery.small`
      margin-top: 20px;
      padding-top: 70px;
    `}

    ${minBreakpointQuery.large`
      margin-top: 30px;
      padding-top: 90px;
    `}

    &:before {
      content: '“';
      position: absolute;
      top: 0;
      left: 50%;
      color: ${brandColours.tertiary};
      font-family: sans-serif;
      ${fontSize(100)};
      line-height: 1;
      transform: translateX(-50%);

      ${minBreakpointQuery.small`
        ${fontSize(140)};
      `}

      ${minBreakpointQuery.large`
        ${fontSize(180)};
      `}
    }
  `;
};

const StyledItems = styled.div`
  ${commonItemsStyles()};
`;

const StyledItemsSlider = styled(Slider)`
  ${commonItemsStyles()};
`;

const StyledItem = styled.figure`
  position: relative;
  margin: 0 auto;
  padding: 10px;
  max-width: 980px;
`;

const StyledBlockquote = styled.blockquote`
  margin: 0;
`;

const StyledQuote = styled.p`
  ${fluidFontSize(
    '20px',
    '40px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.6;
`;

const StyledNameInfo = styled.figcaption`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 26px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 32px;
    ${fontSize(20)};
  `}
`;

const StyledName = styled.p`
  margin: 8px 0;
`;

const StyledInfo = styled.p`
  margin: 8px 0;
  text-transform: uppercase;
`;

const Testimonials = ({ heading, items }) => {
  const ItemsComponent = items.length > 1 ? StyledItemsSlider : StyledItems;

  const sliderOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: true,
    pauseOnHover: true,
  };

  return (
    <StyledTestimonials>
      <Container>
        <Heading>{heading}</Heading>
        <ItemsComponent numSlides={items.length} sliderOptions={sliderOptions}>
          {items.map(({ id, quote, name, information }) => (
            <StyledItem {...(items.length > 1 && { key: id })}>
              <StyledBlockquote>
                <StyledQuote>{quote}</StyledQuote>
              </StyledBlockquote>
              {(name || information) && (
                <StyledNameInfo>
                  {name && <StyledName>{name}</StyledName>}
                  {information && <StyledInfo>{information}</StyledInfo>}
                </StyledNameInfo>
              )}
            </StyledItem>
          ))}
        </ItemsComponent>
      </Container>
    </StyledTestimonials>
  );
};

export default Testimonials;
