import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledTabs = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  text-align: center;
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;

  ${minBreakpointQuery.tiny`
    flex-direction: row;
  `}
`;

const StyledItem = styled.li`
  flex-grow: 1;
`;

const StyledTab = styled.button`
  padding: 20px 10px;
  height: 100%;
  width: 100%;
  color: ${brandColours.primary};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  ${fontSize(16)};
  line-height: 1.5;
  background: none;
  border: none;
  border-bottom: 2px solid ${brandColours.octonary};
  outline: none;
  cursor: pointer;
  transition:
    ${standardTransition('border-color')};

  ${({ active }) => {
    if (active) {
      return `
        border-bottom-color: ${brandColours.primary};
      `;
    }
  }}

  ${minBreakpointQuery.medium`
    padding-top: 24px;
    padding-bottom: 24px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    padding-top: 28px;
    padding-bottom: 28px;
    ${fontSize(20)};
  `}
`;

const StyledContent = styled.article`
  margin: 40px 0;

  ${({ active }) => {
    if (!active) {
      return `
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledContentHeading = styled.h3`
  ${visuallyHidden()};
`;

export const Tabs = ({ heading, items, isSystemPage }) => {
  const [activeTabId, setActiveTabId] = useState(items[0] && items[0].id);

  return (
    items.length > 0 && (
      <StyledTabs>
        <Container narrow={!isSystemPage}>
          {heading && <StyledHeading alt={true}>{heading}</StyledHeading>}
          <StyledList>
            {items.map(({ id, heading }) => (
              <StyledItem>
                <StyledTab
                  active={id === activeTabId}
                  onClick={() => {
                    setActiveTabId(id);
                  }}
                >
                  {heading}
                </StyledTab>
              </StyledItem>
            ))}
          </StyledList>
          {items.map(({ id, heading, content }) => (
            <StyledContent key={id} active={id === activeTabId}>
              <StyledContentHeading>{heading}</StyledContentHeading>
              <HtmlContent html={content} />
            </StyledContent>
          ))}
        </Container>
      </StyledTabs>
    )
  );
};

export default Tabs;
