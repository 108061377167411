import React, { useState } from 'react';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  visuallyHidden,
} from '../styles';
import { HtmlContent, QuoteButton } from './ui';
import { slugify } from '../utils';

//moved up for colour
const StyledImageWrapper = styled.div`
  padding: 10px;
  width: 75px;
  flex-shrink: 0;

  ${minBreakpointQuery.small`
    width: 92px;
  `}
`;

const StyledMixerCategoriesItem = styled.article`
  background-color: ${brandColours.duodenary};
  scroll-margin-top: 110px;
  padding: 30px;

  ${StyledImageWrapper} {
    background-color: ${brandColours.senary};
  }

  &:nth-child(3n - 1) {
    ${StyledImageWrapper} {
      background-color: ${brandColours.quinary};
    }
  }

  &:nth-child(3n) {
    ${StyledImageWrapper} {
      background-color: ${brandColours.septenary};
    }
  }

  ${maxBreakpointQuery.medium`
    margin-left: -30px;
    margin-right: -30px;
  `}

  ${minBreakpointQuery.small`
    scroll-margin-top: 130px;
  `}

  ${minBreakpointQuery.medium`
    scroll-margin-top: 150px;
    padding: 40px 60px;
  `}

  ${minBreakpointQuery.large`
    scroll-margin-top: 180px;
    padding: 50px 80px;
  `}
  
  ${minBreakpointQuery.xxlarge`
    scroll-margin-top: 180px;
    padding: 60px 100px;
  `}
`;

const StyledInner = styled.div``;

const StyledHeader = styled.header`
  display: flex;
  gap: 15px;
  align-items: center;

  ${minBreakpointQuery.small`
      gap: 20px;
    `}

  ${minBreakpointQuery.medium`
      gap: 25px;
    `}

    ${minBreakpointQuery.large`
      gap: 30px;
    `}
`;

const StyledImg = styled(Img)`
  max-width: 55px;
  max-height: 55px;
  width: 100%;
  height: 100%;

  img {
    object-fit: contain !important;
  }

  ${minBreakpointQuery.small`
    max-width: 72px;
    max-height: 72px;
  `}
`;

const StyledHeading = styled.h3`
  ${fluidFontSize(
    '20px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledText = styled.p`
  margin-top: 25px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.medium`
    margin-top: 35px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    ${fontSize(20)};
  `}
`;

const StyledItemLists = styled.div`
  margin-top: 25px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 35px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const StyledItemList = styled.ul`
  display: grid;
  gap: 10px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    gap: 45px 20px;
  `}
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.medium`
    padding: 20px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: ${brandColours.octonary};
    margin-bottom: auto;
    
  `}

  ${minBreakpointQuery.medium`
    border-bottom: 1px dotted ${brandColours.tertiary};
    padding-bottom: 30px;
  `}
`;

const StyledSubHeading = styled.h4`
  ${fontSize(16)};
  position: relative;

  ${maxBreakpointQuery.medium`
    &:before {
      content: '';
      position: absolute;
      right: -20px;
      top: 50%;
      width: 20px;
      height: 3px;
      background-color: ${brandColours.tertiary};
      transform: translateY(-50%);
    }
  `}

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(22)};
  `}

  ${({ $active }) => {
    if (!$active) {
      return css`
        ${maxBreakpointQuery.medium`
          &:after {
            content: '';
            position: absolute;
            right: -20px;
            top: 50%;
            width: 20px;
            height: 3px;
            background-color: ${brandColours.tertiary};
            transform: translateY(-50%) rotate(90deg);
          }
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.medium`
          margin-bottom: 15px;
        `}
      `;
    }
  }}
`;

const StyledContent = styled(HtmlContent)`
  ul {
    margin-bottom: 0;
  }

  ul li {
    padding-left: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      top: 50%;
      left: 0;
      height: 4px;
      width: 4px;
      background-color: ${brandColours.primary};
      transform: translateY(-50%);
    }
  }

  p {
    ${fontSize(12)};
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    ${minBreakpointQuery.small`
      ${fontSize(14)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(16)};
    `}
  }

  ${({ $active }) => {
    if (!$active) {
      return css`
        ${maxBreakpointQuery.medium`
          ${visuallyHidden()};
        `}
      `;
    }
  }}
`;

const StyledButton = styled(QuoteButton)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    display: block;
    margin-top: 25px;
    margin-left: auto;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const MixerCategoriesItem = item => {
  const {
    heading,
    image: { fluid, alt },
    text,
    headingContentBlocks,
    locale,
  } = item;

  const [activeItem, setActiveItem] = useState();

  return (
    <StyledMixerCategoriesItem id={slugify(heading)}>
      <StyledInner>
        <StyledHeader>
          <StyledImageWrapper>
            <StyledImg fluid={fluid} alt={alt} />
          </StyledImageWrapper>
          <StyledHeading>{heading}</StyledHeading>
        </StyledHeader>
        <StyledText>{text}</StyledText>
        <StyledItemLists>
          <StyledItemList>
            {headingContentBlocks.map(({ heading, content }, i) => {
              const display = activeItem === i;

              return (
                <StyledItem key={i}>
                  <StyledSubHeading
                    onClick={() => setActiveItem(display ? undefined : i)}
                    $active={display}
                  >
                    {heading}
                  </StyledSubHeading>
                  <StyledContent html={content} $active={display} />
                </StyledItem>
              );
            })}
          </StyledItemList>
        </StyledItemLists>
        <StyledButton locale={locale} arrow={true} />
      </StyledInner>
    </StyledMixerCategoriesItem>
  );
};

export default MixerCategoriesItem;
