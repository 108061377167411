import React from 'react';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  fontSize,
  headingStyles,
} from '../styles';
import { Video, Overline, QuoteButton } from './ui';

const StyledMediaContent = styled.section``;

const StyledInner = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ flip }) => {
      if (flip) {
        return css`
          flex-direction: row-reverse;
        `;
      }
    }}
  `}
`;

const StyledMedia = styled.div`
  ${minBreakpointQuery.medium`

    ${({ isImage }) => {
      if (isImage) {
        return css`
          width: 50%;
        `;
      } else {
        return css`
          width: 55%;
        `;
      }
    }}
  `}
`;

const StyledImage = styled(Img)``;

const StyledVideo = styled(Video)``;

const StyledContent = styled.div`
  ${maxBreakpointQuery.medium`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.medium`

    ${({ isImage }) => {
      if (isImage) {
        return css`
          width: 40%;
        `;
      } else {
        return css`
          width: 35%;
        `;
      }
    }}
  `}
`;

const StyledHeading = styled.h3`
  ${headingStyles('25px', '35px')};
`;

const StyledText = styled.p`
  margin-top: 20px;
  line-height: 1.8;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
    ${fontSize(20)};
  `}
`;

const StyledButton = styled(QuoteButton)`
  margin-top: 20px;

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const MediaContent = ({
  media,
  overline,
  heading,
  text,
  addQuoteButton,
  locale,
  flip,
}) => (
  <StyledMediaContent>
    <StyledInner flip={flip}>
      <StyledMedia isImage={media.isImage}>
        {media.isImage ? (
          <StyledImage fluid={media.fluid} alt={media.alt} />
        ) : (
          <StyledVideo data={media} controls={false} autoPlay={true} />
        )}
      </StyledMedia>
      <StyledContent isImage={media.isImage}>
        {overline && <Overline>{overline}</Overline>}
        <StyledHeading>{heading}</StyledHeading>
        {text && <StyledText>{text}</StyledText>}
        {addQuoteButton && <StyledButton arrow={true} locale={locale} />}
      </StyledContent>
    </StyledInner>
  </StyledMediaContent>
);

export default MediaContent;
