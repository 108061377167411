import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontSize,
  headingStyles,
} from '../styles';
import { Container, Overline, QuoteButton } from './ui';

const StyledQuoteCta = styled.section``;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;
  padding: 0 30px 40px;
  background-color: ${brandColours.septenary};
  border: 1px solid ${brandColours.octonary};
  border-radius: 30px;

  ${minBreakpointQuery.tiny`
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding-top: 50px;
    padding-right: 0;
    padding-left: 50px;
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
    padding-left: 80px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 100px;
  `}
`;

const StyledContent = styled.div`
  ${maxBreakpointQuery.small`
    order: 2;
  `}
`;

const StyledHeading = styled.h2`
  ${headingStyles(undefined, '45px')};
`;

const StyledText = styled.p`
  margin: 18px 0 22px;
  line-height: 1.7;

  ${minBreakpointQuery.medium`
    margin-top: 22px;
    margin-bottom: 26px;
    ${fontSize(18)};
  `}
`;

const StyledImage = styled(Img)`
  ${maxBreakpointQuery.small`
    order: 1;
    margin-top: -10%;
  `}

  ${minBreakpointQuery.small`
    margin-right: -5%;
  `}

  ${minBreakpointQuery.medium`
    margin-right: -10%;
  `}

  ${minBreakpointQuery.large`
    margin-right: -15%;
  `}
`;

const QuoteCta = ({
  overline,
  heading,
  text,
  image: { fluid, alt },
  locale,
}) => (
  <StyledQuoteCta>
    <Container>
      <StyledInner>
        <StyledContent>
          {overline && <Overline>{overline}</Overline>}
          <StyledHeading>{heading}</StyledHeading>
          {text && <StyledText>{text}</StyledText>}
          <QuoteButton arrow={true} locale={locale} />
        </StyledContent>
        <StyledImage fluid={fluid} alt={alt} />
      </StyledInner>
    </Container>
  </StyledQuoteCta>
);

export default QuoteCta;
