import React, { useState } from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAccordion = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledItem = styled.article`
  margin: 10px 0;
  padding: 22px 30px;
  background-color: ${brandColours.octonary};
  border-radius: 10px;

  ${minBreakpointQuery.small`
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 28px 40px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 34px 50px;
  `}
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  font-weight: ${fontWeights.medium};
  ${fluidFontSize(
    '16px',
    '25px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-right: 35px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
  `}

  &:after {
    content: ${({ display }) => (display ? "'-'" : "'+'")};
    position: absolute;
    top: 50%;
    right: 0;
    color: ${brandColours.primary};
    font-weight: ${fontWeights.regular};
    ${fontSize(35)};
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(40)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(45)};
    `}
  }
`;

const StyledContent = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const Accordion = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledAccordion>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        {items.map(({ heading, content }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem>
              <StyledSubHeading
                display={display}
                onClick={() => setActiveItem(display ? undefined : i)}
              >
                {heading}
              </StyledSubHeading>
              <StyledContent html={content} display={display} />
            </StyledItem>
          );
        })}
      </Container>
    </StyledAccordion>
  );
};

export default Accordion;
