import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, ArrowLink } from './ui';
import NewsCard from './NewsCard';
import {
  getCollectionLocaleFields,
  getSingleLocaleFields,
  translateString,
} from '../utils';

const StyledLatestNews = styled.section`
  position: relative;
  ${sectionMargins()};
  padding-top: 20px;

  ${maxBreakpointQuery.smedium`
    overflow: hidden;
  `}

  ${minBreakpointQuery.tiny`
    padding-top: 40px;
  `}

  ${minBreakpointQuery.small`
    padding-top: 60px;
  `}

  ${minBreakpointQuery.medium`
    padding-top: 80px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 100px;
  `}
`;

const StyledInner = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledGroup = styled.div``;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-bottom: 50px;
  `}
`;

const StyledNewsCard = styled(NewsCard)`
  ${({ second }) => {
    if (second) {
      return `
      
        ${minBreakpointQuery.smedium`
          bottom: 80px;
        `}

        ${minBreakpointQuery.large`
          bottom: 100px;
        `}

        ${minBreakpointQuery.xxlarge`
          bottom: 120px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          bottom: 140px;
        `}
      `;
    }
  }}
`;

const StyledLinkWrapper = styled.div`
  ${maxBreakpointQuery.smedium`
    text-align: center;
  `}

  ${minBreakpointQuery.smedium`
    text-align: right;
  `}
`;

const StyledLink = styled(ArrowLink)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-top: 50px;
  `}
`;

const LatestNews = ({ heading, items, locale }) => {
  const { allDatoCmsNews, allDatoCmsNewsArchive } = useStaticQuery(graphql`
    query LatestNewsQuery {
      allDatoCmsNews(
        filter: { slug: { ne: null } }
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        group(field: locale, limit: 2) {
          fieldValue
          nodes {
            ...NewsCardFragment
          }
        }
      }
      allDatoCmsNewsArchive {
        nodes {
          ...LinkFragment
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsNews, locale);
  const newsArchive = getSingleLocaleFields(allDatoCmsNewsArchive, locale);

  const posts = items || nodes;

  return (
    posts.length > 1 && (
      <StyledLatestNews>
        <Container>
          <StyledInner>
            <StyledGroup>
              <StyledHeading>
                {heading || translateString('Recent News', locale)}
              </StyledHeading>
              <StyledNewsCard
                key={posts[0].id}
                overline={posts[0].category.name}
                heading={posts[0].title}
                link={posts[0]}
                image={posts[0].featuredImage}
              />
            </StyledGroup>
            <StyledGroup>
              <StyledNewsCard
                key={posts[1].id}
                overline={posts[1].category.name}
                heading={posts[1].title}
                link={posts[1]}
                image={posts[1].featuredImage}
                second={true}
              />
              <StyledLinkWrapper>
                <StyledLink to={newsArchive}>
                  {translateString('View all news', locale)}
                </StyledLink>
              </StyledLinkWrapper>
            </StyledGroup>
          </StyledInner>
        </Container>
      </StyledLatestNews>
    )
  );
};

export default LatestNews;
