import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
} from '../styles';
import { Container } from './ui';
import { slugify } from '../utils';
import MixerCategoriesItem from './MixerCategoriesItem';

const StyledMixerCategories = styled.section``;

const StyledContainer = styled(Container)`
  max-width: 1240px;
`;

const StyledHeading = styled.h2`
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '22px',
    '45px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};
  margin-bottom: 30px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}
`;

const StyledList = styled.ul`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.small`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyledListItem = styled.li`
  position: relative;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: ${brandColours.senary};

  &:nth-child(3n - 1) {
    background-color: ${brandColours.quinary};
  }

  &:nth-child(3n) {
    background-color: ${brandColours.septenary};
  }

  ${minBreakpointQuery.small`
    padding: 25px;
    padding-bottom: 0;
  `}

  ${minBreakpointQuery.large`
    padding: 30px;
    padding-bottom: 0;
  `}
`;

const StyledListLink = styled.a`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledSubHeading = styled.h3`
  ${fontSize(18)};
  line-height: 1.6;

  ${minBreakpointQuery.small`
    ${fontSize(21)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: auto;
  gap: 20px;
`;

const StyledTag = styled.span`
  color: ${standardColours.white};
  background-color: ${brandColours.tertiary};
  text-transform: uppercase;
  padding: 12px;
  width: 115px;
  margin-left: -20px;
  ${fontSize(12)};

  ${minBreakpointQuery.small`
    margin-left: -25px;
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(14)};
    width: 135px;
    padding: 18px;
  `}

  ${minBreakpointQuery.large`
    margin-left: -30px;
  `}
`;

const StyledImg = styled(Img)`
  max-width: 215px;
  max-height: 230px;
  width: 100%;
  height: 100%;

  img {
    object-fit: contain !important;
  }
`;

const StyledMixerCategoriesInner = styled.div`
  ${sectionPaddings(undefined, '80px')}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.small`
    gap: 30px;
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 70px;
  `}
`;

const MixerCategories = ({ heading, items, locale }) => (
  <StyledMixerCategories>
    <StyledContainer narrow={true}>
      <StyledHeading>{heading}</StyledHeading>
      <div>
        <StyledList>
          {items.map(({ heading, image: { fluid, alt } }, id) => (
            <StyledListItem key={id}>
              <StyledListLink href={`#${slugify(heading)}`}>
                <StyledSubHeading>{heading}</StyledSubHeading>
                <StyledContent>
                  <StyledTag>Details anzeigen</StyledTag>
                  <StyledImg fluid={fluid} alt={alt} />
                </StyledContent>
              </StyledListLink>
            </StyledListItem>
          ))}
        </StyledList>
      </div>
      <StyledMixerCategoriesInner>
        <StyledItems>
          {items.map((item, id) => (
            <MixerCategoriesItem key={id} {...item} locale={locale} />
          ))}
        </StyledItems>
      </StyledMixerCategoriesInner>
    </StyledContainer>
  </StyledMixerCategories>
);

export default MixerCategories;
