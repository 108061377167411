import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  sectionMargins,
  fontSize,
  minBreakpointQuery,
} from '../styles';
import { Container, Heading, InViewAnimation } from './ui';

const StyledContentList = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 40px;
  max-width: 720px;

  ${minBreakpointQuery.medium`
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 80px;
  `}
`;

const StyledItems = styled.ul`
  display: grid;
  row-gap: 20px;

  ${minBreakpointQuery.tiny`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  `}

  ${minBreakpointQuery.medium`
    row-gap: 40px;
    column-gap: 70px;
  `}

  ${minBreakpointQuery.large`
    row-gap: 50px;
    column-gap: 150px;
  `}
`;

const StyledItem = styled.li`
  padding-bottom: 30px;
  border-bottom: 1px dashed ${brandColours.primary};

  ${minBreakpointQuery.medium`
    padding-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-bottom: 50px;
  `}
`;

const StyledItemHeading = styled.h3`
  ${fontSize(21)};

  ${minBreakpointQuery.tiny`
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(25)};
  `}
`;

const StyledItemText = styled.p`
  margin-top: 20px;
  line-height: 1.8;
  white-space: break-spaces;

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}
`;

const ContentList = ({ heading, items }) => {
  return (
    <StyledContentList>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledItems>
          {items.map(({ id, heading, text }) => (
            <StyledItem key={id}>
              <InViewAnimation>
                <StyledItemHeading>{heading}</StyledItemHeading>
                <StyledItemText>{text}</StyledItemText>
              </InViewAnimation>
            </StyledItem>
          ))}
        </StyledItems>
      </Container>
    </StyledContentList>
  );
};

export default ContentList;
